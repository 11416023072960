.left {
  padding-left: 30px;
}

.menu {
  font-size: 14px;
}

.text {
  margin-left: 5px;
}

.dropdown {
  padding-top: 10px;
  font-size: 14px;
}

.dropdownRight {
  margin-right: 20px;
}

.list {
  font-size: 14px;
}