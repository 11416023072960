.login {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}

.content {
  margin-top: 30px;
  display: inline-block;
  text-align: left;
  width: 350px;
  max-width: 100%;
}

.content label {
  font-weight: 500;
}

.submit {
  text-align: center;
}