.title {
    margin-top: 10px;
    margin-bottom: 5px;
}

.header {
    text-align: left;
}

.text {
    margin-left: 20px;
}

.showAll {
    font-size: larger;
}

.showToggle {
    padding-top: 8px;
}

.lastUpdate {
    padding-top: 10px;
    font-size: larger;
}