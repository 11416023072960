body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
  line-height: 1.224;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.title{
  font-family: Titillium Web;
}

.nav-tabs .nav-link {
  cursor: pointer !important;
}

.my-custom-react-select__menu {
  z-index: 9999999 !important;
  position: relative !important;
  width: 300px !important;
  /* overflow-y: scroll !important; */
} 
.dropdown-content{
  z-index: 9999999 !important;
  position: relative !important;
}

.textAllocation{
  text-align: left;
  font-weight: bolder;
  height: 40px;
}

.childSpan { line-height: 40px; vertical-align: middle; }