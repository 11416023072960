.form {
  background-color: aliceblue;
  border-color:rgb(241, 240, 255) !important;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  margin-bottom: 5px;
}

.content {
  margin-top: 10px;
  margin-bottom: 10px;
}

.submit {
  text-align: center;
}